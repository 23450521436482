import imprt from "../images/import.svg";
import exprt from "../images/export.svg";
import forexMarker from "../images/forex-marker.svg";
import {
  UserInfo,
  dogeData,
  ethData,
  qrData,
  tronData,
  usdtData,
} from "../contexts/pageContext";
import { useContext, useState, useEffect } from "react";
import btc from "../images/BTC.svg";
import eth from "../images/eth.svg";
import close from "../images/close.svg";
import copy from "../images/copy.svg";
import doge from "../images/doge.svg";
import usdt from "../images/Tether.svg";
import bt from "../images/bt.svg";
import imgExport from "../images/imgexport.svg";
import { motion, warning } from "framer-motion";
import load from "../images/load.gif";
import { initializeApp } from "firebase/app";
import { getFirestore, collection, addDoc } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import danger from "../images/danger.svg";
import warner from "../images/warning.svg";
import EurComp from "../widgetComps/eurusd";
import GbpComp from "../widgetComps/gbpusd";
import JpyComp from "../widgetComps/jpyusd";
import StocksTab from "../widgetComps/crossRates";
import tron from "../images/tron.svg";

const ForexDash = () => {
  const [userData, setUserData] = useContext(UserInfo);
  const [dataSet, setDataSet] = useState([]);
  const [withdrawPopup, setWithdrawPopup] = useState(false);
  const [depoPopup, setDepoPopup] = useState(false);
  const [amount, setAmount] = useState("");
  const [loading, setLoading] = useState("No");
  const [file, setFile] = useState(null);
  const [lowbal, setLowBal] = useState(false);
  const [success, setSuccess] = useState(false);
  const [nameFile, setNameFile] = useState(false);
  const [count, setCount] = useState(0);
  const [tradePopup, setTradePopup] = useState(false);
  const [completePopup, setCompletePopup] = useState(false);
  const [tradeName, setTradeName] = useState("Bitcoin");
  const [tradeAmount, setTradeAmount] = useState("0");
  const [lowBalTrade, setLowBalTrade] = useState(false);
  const [walletAddy, setWalletAddy] = useState("");
  const [withdrawAmount, setWithdrawAmount] = useState("");
  const [lowBalance, setLowBalance] = useState(false);
  const [upgrade, setUpgrade] = useState(false);
  const [codeImage, setCodeImage] = useContext(qrData);
  const [ethImg, setEthImg] = useContext(ethData);
  const [dogeImg, setDogeImg] = useContext(dogeData);
  const [usdtImg, setUsdtImg] = useContext(usdtData);
  const [tronImg, setTronImg] = useContext(tronData);

  const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
  };

  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);
  const storage = getStorage(app);

  // addy copy
  const handleCopyClick = (e) => {
    const parent = e.target.parentElement;
    const elements = parent.children;
    const addy = elements[0];

    // Check if the addy element is an input element
    if (addy && addy instanceof HTMLInputElement) {
      addy.select();
      addy.setSelectionRange(0, 99999);
      navigator.clipboard.writeText(addy.value);
    }
  };

  //date and time
  // For todays date;  // Function to get the ordinal suffix
  function getOrdinalSuffix(day) {
    if (day > 3 && day < 21) return "th";
    switch (day % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  }

  // Format date as "20th, October 2024"
  function formatDate(dateObj) {
    const day = dateObj.getDate();
    const month = dateObj.toLocaleString("default", { month: "long" });
    const year = dateObj.getFullYear();
    return `${day}${getOrdinalSuffix(day)}, ${month} ${year}`;
  }

  // Format time as "11:00pm"
  function formatTime(dateObj) {
    return dateObj
      .toLocaleString("en-US", {
        hour: "numeric",
        minute: "2-digit",
        hour12: true,
      })
      .toLowerCase(); // Convert to lowercase for "am/pm"
  }

  // Usage
  const newDate = new Date();
  const date = formatDate(newDate); // e.g., "20th, October 2024"
  const time = formatTime(newDate); // e.g., "11:00pm"

  //document data
  const userName = userData.name;
  const userUid = userData.uid;
  const transType = "Deposit";
  const transState = "Pending";

  const imageName = userName + date;

  const submitImg = () => {
    setLoading("Yes");

    if (!file) {
      alert("Please upload a file first.");
      setLoading("No");
      return;
    }

    // Check file size (500 KB limit)
    if (file.size > 500 * 1024) {
      alert("File is too large. Please upload a file under 500 KB.");
      setLoading("No");
      return;
    }

    // Convert file to Base64
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      const base64String = reader.result; // Base64-encoded image

      const transactionDoc = collection(db, "transactions");
      const docData = {
        name: userName,
        uid: userUid,
        transactionType: transType,
        transactionState: transState,
        tag: "Forex",
        amount: amount,
        date: date,
        base64Image: base64String,
        time: time,
      };

      // Save document to Firestore
      addDoc(transactionDoc, docData)
        .then(() => {
          setLoading("No");
          const confirm = document.getElementById("confirm");
          confirm.style.display = "block";
        })
        .catch((error) => {
          console.error("Error saving document: ", error);
          setLoading("No");
        });
    };

    reader.onerror = (error) => {
      console.error("Error converting file to Base64: ", error);
      setLoading("No");
    };
  };

  // Convert Totalbalance and amount to integer
  const totalBalanceAsString = userData.Totalbal;
  const amountAsString = amount;
  const tradeAmountAsString = tradeAmount;
  const ForexDepositAsString = userData.ForexDeposit;

  const totalBalance = parseInt(totalBalanceAsString);
  const amountAsInt = parseInt(amountAsString);
  const tradeAmountAsInt = parseInt(tradeAmountAsString);
  const StockDepositAsInt = parseInt(ForexDepositAsString);
  const witdrawTry = parseInt(withdrawAmount);

  const transfer = () => {
    if (amountAsInt > totalBalance || totalBalance === 0) {
      setLowBal(true);
    } else if (totalBalance > amountAsInt || totalBalance == amountAsInt) {
      setLowBal(false);
      const transactionDoc = collection(db, "transactions");
      const docData = {
        name: userName,
        uid: userUid,
        transactionType: transType,
        transactionState: transState,
        tag: "Forex",
        amount: amountAsInt,
        date: date,
        time: time,
      };
      addDoc(transactionDoc, docData).then(() => {
        setSuccess(true);
      });
    }
  };

  //submit withdrawal
  const submitWithdrawal = () => {
    if (witdrawTry == 0 || witdrawTry > totalBalance) {
      setLowBalance(true);
    } else if (totalBalance > witdrawTry || totalBalance === witdrawTry) {
      setLoading("Yes");
      const transactionDoc = collection(db, "transactions");
      const docData = {
        name: userName,
        uid: userUid,
        transactionType: "Withdrawal",
        transactionState: transState,
        tag: "Forex",
        amount: withdrawAmount,
        date: date,
        time: time,
        addy: walletAddy,
      };
      addDoc(transactionDoc, docData).then(() => {
        setLoading("No");
        setUpgrade(true);
      });
    }
  };

  return (
    <>
      <div className=" text-white">
        <div className=" w-full bg-[#1e1e1e] h-[185px] md:h-auto rounded-[15px] p-6 flex relative flex-col md:flex-row justify-between">
          <span className=" flex flex-col">
            <span className=" flex items-start ">
              <span className="  h-[32px] w-[32px] md:h-[45px] md:w-[45px] mr-2 rounded-[50%] bg-[#03AAC7] flex justify-center items-center">
                <img src={forexMarker} alt="" />
              </span>
              <div className=" flex flex-col mt-2 md:mt-3 w-full">
                <p className=" font-Lato md:text-[24px] font-normal text-sm md:text-base">
                  Forex
                </p>
                <div className=" mt-5 w-full hidden md:flex flex-row justify-between md:space-x-8">
                  <span className=" -ml-8 md:ml-0 flex flex-col">
                    <p className=" font-Lato font-normal text-[#FFFFFFCC] text-xs md:text-base">
                      Total balance
                    </p>
                    <p className=" font-Lato font-normal text-sm md:text-xl">
                      {"$" + userData.ForexTotalbal + ".00"}
                    </p>
                  </span>
                  <span className=" flex flex-col">
                    <p className=" font-Lato font-normal text-[#FFFFFFCC] text-xs md:text-base">
                      Deposit
                    </p>
                    <p className=" font-Lato font-normal text-sm md:text-xl">
                      {"$" + userData.ForexDeposit + ".00"}
                    </p>
                  </span>
                  <span className=" flex flex-col">
                    <p className=" font-Lato font-normal text-[#FFFFFFCC] text-xs md:text-base">
                      Profit
                    </p>
                    <p className=" font-Lato font-normal text-sm md:text-xl">
                      {"$" + userData.ForexProfits + ".00"}
                    </p>
                  </span>
                </div>
              </div>
            </span>
          </span>
          <span className=" mt-3 md:mt-0 absolute top-3 right-6 flex flex-row space-x-6 md:space-x-10">
            <label
              htmlFor=""
              onClick={() => {
                setDepoPopup(true);
              }}
              className=" flex flex-col items-center font-Lato font-semibold text-sm"
            >
              <button className=" bg-[#03AAC7] h-[32px] w-[32px] md:h-[45px] md:w-[45px] flex justify-center items-center mb-1 rounded-[50%]">
                <img src={imprt} alt="" />
              </button>
              Deposit
            </label>
            <label
              htmlFor=""
              onClick={() => {
                setWithdrawPopup(true);
              }}
              className=" flex flex-col items-center font-Lato font-semibold text-sm"
            >
              <button className=" bg-[#03AAC7] h-[32px] w-[32px] md:h-[45px] md:w-[45px] flex justify-center items-center mb-1 rounded-[50%]">
                <img src={exprt} alt="" />
              </button>
              Withdraw
            </label>
          </span>

          <div className="  border-t pt-3 w-full flex md:hidden border-[#EAEBF01A] flex-row justify-between ">
            <span className=" md:ml-0 flex flex-col">
              <p className=" font-Lato font-normal text-[#FFFFFFCC] text-xs md:text-base">
                Total balance
              </p>
              <p className=" font-Lato font-normal text-sm md:text-xl">
                {"$" + userData.ForexTotalbal + ".00"}
              </p>
            </span>
            <span className=" flex flex-col">
              <p className=" font-Lato font-normal text-[#FFFFFFCC] text-xs md:text-base">
                Deposit
              </p>
              <p className=" font-Lato font-normal text-sm md:text-xl">
                {"$" + userData.ForexDeposit + ".00"}
              </p>
            </span>
            <span className=" flex flex-col">
              <p className=" font-Lato font-normal text-[#FFFFFFCC] text-xs md:text-base">
                Profit
              </p>
              <p className=" font-Lato font-normal text-sm md:text-xl">
                {"$" + userData.ForexProfits + ".00"}
              </p>
            </span>
          </div>
        </div>

        <div className=" w-full flex flex-col md:flex-row space-y-6 md:space-y-0 justify-between mt-[1.5em]">
          <div className=" w-full md:w-1/3 h-[180px] p-2 border rounded-[15px] bg-[#1E1E1E33] border-[#EAEBF01A]">
            <EurComp />
          </div>

          <div className=" w-full md:w-1/3 h-[180px] p-2 border rounded-[15px] md:mx-4 bg-[#1E1E1E33] border-[#EAEBF01A]">
            <GbpComp />
          </div>

          <div className=" w-full md:w-1/3 h-[180px] p-2 border rounded-[15px] bg-[#1E1E1E33] border-[#EAEBF01A]">
            <JpyComp />
          </div>
        </div>

        <div className="w-full mt-[1.5em] h-[400px] overflow-hidden p-4 border rounded-[15px] bg-[#1E1E1E33] border-[#EAEBF01A]">
          <StocksTab />
        </div>
      </div>
      {depoPopup && (
        <motion.div
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.2 }}
          className="w-[100%] h-[100vh] bg-[rgba(0,0,0,0.7)] z-[999] fixed text-white top-0 left-0 flex justify-center items-center md:px-5 overflow-y-auto"
        >
          <div className="h-[85vh] ">
            <div className=" w-full md:w-[700px] lg:w-[700px] rounded-[30px] bg-[#2b2b2b] ">
              <div className=" flex flex-row justify-between border-b p-6 border-[#EAEBF01A]">
                <span className="flex flex-col">
                  <p className=" font-Lato font-medium text-xl">Deposit</p>
                  <p className=" font-Lato font-normal text-xs text-[#FFFFFFCC]">
                    Send only to one of the following address
                  </p>
                </span>
                <img
                  src={close}
                  onClick={() => {
                    setDepoPopup(false);
                  }}
                  alt=""
                />
              </div>
              <div className=" w-full px-6 md:px-6 pt-6 pb-3">
                <div className=" border-[#EAEBF033] border rounded-[30px] flex flex-col pt-2 justify-center items-center border-dashed">
                  <input
                    type="text"
                    placeholder="$0.00"
                    onKeyUp={(e) => {
                      setAmount(e.target.value);
                    }}
                    className=" w-[100px] text-center myInput font-Lato font-normal text-2xl placeholder:font-Lato placeholder:font-normal placeholder:text-center placeholder:text-2xl text-[#FFFFFF99] placeholder:text-[#FFFFFF99] bg-transparent h-[50px]"
                  />
                  <p className=" pb-2 font-Open-Sans text-sm font-normal text-[#FFFFFFCC]">
                    Enter Amount
                  </p>
                </div>
              </div>
              <div className=" w-full px-6 md:px-6 space-y-2">
                <div className=" w-full border border-[#EAEBF033] p-3 rounded-[40px] flex items-center justify-between">
                  <span className=" flex items-center space-x-2">
                    <img src={btc} alt="" />
                    <p className=" font-Open-Sans text-sm font-normal">BTC</p>
                  </span>
                  <div className=" relative w-[94px] md:w-[60%] flex bg-[#1E1E1E] p-2 rounded-[30px]">
                    <input
                      type="text"
                      readOnly
                      value={"bc1qj6wfphulvuexps3s3mv0wysfw40ng5gmkymyrg"}
                      className=" w-[90%] myInput text-[#FFFFFFCC] bg-transparent"
                    />
                    <img src={copy} onClick={handleCopyClick} alt="" />
                  </div>
                  <button
                    onClick={() => setCodeImage(true)}
                    className=" text-center bg-[#03AAC7] py-2 px-2 font-Lato text-xs font-normal rounded-[20px]"
                  >
                    QR Code
                  </button>
                </div>

                <div className=" w-full border border-[#EAEBF033] p-3 rounded-[40px] flex items-center justify-between">
                  <span className=" flex items-center space-x-2">
                    <img src={eth} alt="" />
                    <p className=" font-Open-Sans text-sm md:text-sm font-normal">
                      ETH
                    </p>
                  </span>
                  <div className=" relative w-[94px] md:w-[60%] flex bg-[#1E1E1E] p-2 rounded-[30px]">
                    <input
                      type="text"
                      readOnly
                      value={"0x59e4Ad35E1AF028282299b15fb1C5d0190BB1534"}
                      className=" w-[90%] myInput text-[#FFFFFFCC] bg-transparent"
                    />
                    <img src={copy} onClick={handleCopyClick} alt="" />
                  </div>
                  <button
                    onClick={() => setEthImg(true)}
                    className=" text-center bg-[#03AAC7] py-2 px-2 font-Lato text-xs font-normal rounded-[20px]"
                  >
                    QR Code
                  </button>
                </div>

                <div className=" w-full border border-[#EAEBF033] p-3 rounded-[40px] flex items-center justify-between">
                  <span className=" flex items-center space-x-2">
                    <img src={doge} alt="" />
                    <p className=" font-Open-Sans text-sm font-normal">DOGE</p>
                  </span>
                  <div className=" relative w-[94px] md:w-[60%] flex bg-[#1E1E1E] p-2 rounded-[30px]">
                    <input
                      type="text"
                      readOnly
                      value={"DFZfKD5UztJQ9wkKFCg1otS22Hp14bg5Gm"}
                      className=" w-[90%] myInput text-[#FFFFFFCC] bg-transparent"
                    />
                    <img src={copy} onClick={handleCopyClick} alt="" />
                  </div>
                  <button
                    onClick={() => setDogeImg(true)}
                    className=" text-center bg-[#03AAC7] py-2 px-2 font-Lato text-xs font-normal rounded-[20px]"
                  >
                    QR Code
                  </button>
                </div>

                <div className=" w-full border border-[#EAEBF033] p-3 rounded-[40px] flex items-center justify-between">
                  <span className=" flex items-center space-x-2">
                    <img src={usdt} alt="" />
                    <p className=" font-Open-Sans text-xs md:text-sm font-normal">
                      USDT(TRC20)
                    </p>
                  </span>
                  <div className=" relative w-[94px] md:w-[60%] flex bg-[#1E1E1E] p-2 rounded-[30px]">
                    <input
                      type="text"
                      readOnly
                      value={"TSQfdRch8w78zfgnacwzYSzEFwUSUW179Y"}
                      className=" w-[90%] myInput text-[#FFFFFFCC] bg-transparent"
                    />
                    <img src={copy} onClick={handleCopyClick} alt="" />
                  </div>
                  {/* <button
                    onClick={() => setUsdtImg(true)}
                    className=" text-center bg-[#03AAC7] py-2 px-2 font-Lato text-xs font-normal rounded-[20px]"
                  >
                    QR Code
                  </button> */}
                </div>

                <div className=" w-full border border-[#EAEBF033] p-3 rounded-[40px] flex items-center justify-between">
                  <span className=" flex items-center space-x-2">
                    <img src={tron} alt="" />
                    <p className=" font-Open-Sans text-sm font-normal">Tron</p>
                  </span>
                  <div className=" relative w-[94px] md:w-[60%] flex bg-[#1E1E1E] p-2 rounded-[30px]">
                    <input
                      type="text"
                      readOnly
                      value={"TSQfdRch8w78zfgnacwzYSzEFwUSUW179Y"}
                      className=" w-[90%] myInput text-[#FFFFFFCC] bg-transparent"
                    />
                    <img src={copy} onClick={handleCopyClick} alt="" />
                  </div>
                  <button
                    onClick={() => setTronImg(true)}
                    className=" text-center bg-[#03AAC7] py-2 px-2 font-Lato text-xs font-normal rounded-[20px]"
                  >
                    QR Code
                  </button>
                </div>

                <div className=" w-full border border-[#EAEBF033] p-3 rounded-[40px] flex items-center justify-between">
                  <span className=" flex items-center space-x-2">
                    <img src={bt} alt="" />
                    <p className=" font-Open-Sans text-sm font-normal">TFR</p>
                  </span>
                  <div className=" relative w-[94px] md:w-[60%] flex bg-[#1E1E1E] p-2 rounded-[30px]">
                    <input
                      type="text"
                      readOnly
                      value={"Contact support for assistance"}
                      className=" w-[100%] text-[#FFFFFFCC] bg-transparent"
                    />
                  </div>
                  <button className=" text-center bg-[#03AAC7] py-2 px-2 font-Lato text-xs font-normal rounded-[20px]">
                    Support
                  </button>
                </div>
              </div>
              <div className=" py-3 w-full px-6 md:px-6">
                <div className="p-6 bg-[#1e1e1e] text-white w-full flex flex-col justify-center items-center rounded-[20px]">
                  <button
                    type="file"
                    className=" w-[60px] h-[60px] rounded-[50%] bg-transparent flex justify-center items-center relative"
                  >
                    <input
                      type="file"
                      onChange={(e) => {
                        setFile(e.target.files[0]);
                        setNameFile(true);
                      }}
                      className=" w-[60px] h-[60px] cursor-pointer z-50 opacity-0"
                      name=""
                      id=""
                    />
                    <img
                      src={imgExport}
                      className=" absolute z-10 top-3 left-[10px]"
                      alt=""
                    />
                  </button>
                  <p className=" font-Lato font-medium text-center text-base md:text-xl mt-1">
                    Click here to upload proof of payment
                  </p>
                  {nameFile && (
                    <p className=" font-Lato font-medium text-xs md:text-sm text-[[#FFFFFFCC] mt-1">
                      {imageName}
                    </p>
                  )}
                </div>
              </div>
              <div className=" w-full px-6 md:px-6 py-3">
                <button
                  onClick={submitImg}
                  className=" py-2 px-4 bg-[#03AAC7] font-Lato font-semibold text-center rounded-[30px] ml-auto block"
                >
                  {loading === "No" && "Submit"}
                  {loading === "Yes" && <img className=" w-6 h-6" src={load} />}
                </button>
                <p
                  id="confirm"
                  className=" ml-auto w-full text-right hidden text-[8px] md:text-xs mt-2 font-Nunito font-medium text-[#0CD16B]"
                >
                  *Your transaction has been received and will be processed
                  shortly
                </p>
              </div>
            </div>
          </div>
        </motion.div>
      )}
      {withdrawPopup && (
        <motion.div
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.2 }}
          className="w-[100%] h-[100vh] bg-[rgba(0,0,0,0.7)] z-[999] fixed text-white top-0 left-0 flex justify-center items-center md:px-5 overflow-y-auto"
        >
          <div className="h-[85vh] ">
            <div className=" w-full md:w-[700px] lg:w-[700px] rounded-[30px] bg-[#2b2b2b] ">
              <div className=" flex flex-row justify-between border-b p-6 border-[#EAEBF01A]">
                <span className="flex flex-col">
                  <p className=" font-Lato font-medium text-xl">Withdraw</p>
                  <p className=" font-Lato font-normal text-xs text-[#FFFFFFCC]">
                    Please double-check the recipient's wallet address before
                    withdrawing your funds.
                  </p>
                </span>
                <img
                  src={close}
                  onClick={() => {
                    setWithdrawPopup(false);
                  }}
                  alt=""
                />
              </div>
              <div className=" w-full px-6 md:px-6 pt-6 pb-3">
                <div className=" border-[#EAEBF033] border rounded-[30px] flex flex-col pt-2 justify-center items-center border-dashed">
                  <input
                    type="text"
                    placeholder="$0.00"
                    onKeyUp={(e) => {
                      setWithdrawAmount(e.target.value);
                    }}
                    className=" w-[100px] text-center myInput font-Lato font-normal text-2xl placeholder:font-Lato placeholder:font-normal placeholder:text-center placeholder:text-2xl text-[#FFFFFF99] placeholder:text-[#FFFFFF99] bg-transparent h-[50px]"
                  />
                  <p className=" pb-2 font-Open-Sans text-sm font-normal text-[#FFFFFFCC]">
                    Enter Amount
                  </p>
                </div>
              </div>

              <div className=" w-full px-6 md:px-6 space-y-2">
                <div className=" w-full border border-[#EAEBF033] p-3 rounded-[40px] flex items-center justify-between">
                  <span className=" flex items-center space-x-2">
                    <img src={btc} alt="" />
                    <p className=" font-Open-Sans text-xs md:text-sm font-normal">
                      BTC
                    </p>
                  </span>
                  <div className=" relative w-[73%] md:w-[85%] flex bg-[#1E1E1E] py-2 px-3 rounded-[30px]">
                    <input
                      onKeyUp={(e) => {
                        setWalletAddy(e.target.value);
                      }}
                      type="text"
                      placeholder="Paste wallet...."
                      className=" font-Open-Sans text-base font-normal placeholder:font-Open-Sans placeholder:text-[#ffffffcc] w-[100%] myInput text-[#FFFFFFCC] bg-transparent"
                    />
                  </div>
                </div>

                <div className=" w-full border border-[#EAEBF033] p-3 rounded-[40px] flex items-center justify-between">
                  <span className=" flex items-center space-x-2">
                    <img src={eth} alt="" />
                    <p className=" font-Open-Sans text-xs md:text-sm font-normal">
                      ETH(BEP 20)
                    </p>
                  </span>
                  <div className=" relative w-[60%] md:w-[80%] flex bg-[#1E1E1E] py-2 px-3 rounded-[30px]">
                    <input
                      type="text"
                      onKeyUp={(e) => {
                        setWalletAddy(e.target.value);
                      }}
                      placeholder="Paste wallet...."
                      className=" font-Open-Sans text-base font-normal placeholder:font-Open-Sans placeholder:text-[#ffffffcc] w-[100%] myInput text-[#FFFFFFCC] bg-transparent"
                    />
                  </div>
                </div>

                <div className=" w-full border border-[#EAEBF033] p-3 rounded-[40px] flex items-center justify-between">
                  <span className=" flex items-center space-x-2">
                    <img src={doge} alt="" />
                    <p className=" font-Open-Sans text-xs md:text-sm font-normal">
                      DOGE
                    </p>
                  </span>
                  <div className=" relative w-[73%] md:w-[85%] flex bg-[#1E1E1E] py-2 px-3 rounded-[30px]">
                    <input
                      type="text"
                      onKeyUp={(e) => {
                        setWalletAddy(e.target.value);
                      }}
                      placeholder="Paste wallet...."
                      className=" font-Open-Sans text-base font-normal placeholder:font-Open-Sans placeholder:text-[#ffffffcc] w-[100%] myInput text-[#FFFFFFCC] bg-transparent"
                    />
                  </div>
                </div>

                <div className=" w-full border border-[#EAEBF033] p-3 rounded-[40px] flex items-center justify-between">
                  <span className=" flex items-center space-x-2">
                    <img src={usdt} alt="" />
                    <p className=" font-Open-Sans text-xs md:text-sm font-normal">
                      USDT
                    </p>
                  </span>
                  <div className=" relative w-[73%] md:w-[85%] flex bg-[#1E1E1E] py-2 px-3 rounded-[30px]">
                    <input
                      type="text"
                      onKeyUp={(e) => {
                        setWalletAddy(e.target.value);
                      }}
                      placeholder="Paste wallet...."
                      className=" font-Open-Sans text-base font-normal placeholder:font-Open-Sans placeholder:text-[#ffffffcc] w-[100%] myInput text-[#FFFFFFCC] bg-transparent"
                    />
                  </div>
                </div>

                <div className=" w-full border border-[#EAEBF033] p-3 rounded-[40px] flex items-center justify-between">
                  <span className=" flex items-center space-x-2">
                    <img src={bt} alt="" />
                    <p className=" font-Open-Sans text-xs md:text-sm font-normal">
                      TFR
                    </p>
                  </span>
                  <div className=" relative w-[50%] md:w-[70%] flex bg-[#1E1E1E] py-2 px-3 rounded-[30px]">
                    <input
                      type="text"
                      readOnly
                      value={"Contact support for assistance"}
                      className=" w-[100%] text-[#FFFFFFCC] bg-transparent"
                    />
                  </div>
                  <button className=" text-center bg-[#03AAC7] py-2 px-2 font-Lato text-xs font-normal rounded-[20px]">
                    Support
                  </button>
                </div>
              </div>
              <div className=" w-full px-6 md:px-6 py-3">
                <button
                  onClick={submitWithdrawal}
                  className=" w-full flex justify-center items-center h-[45px] bg-[#03AAC7] font-Lato font-semibold text-center rounded-[30px] ml-auto"
                >
                  {loading === "No" && "Process Withdrawal"}
                  {loading === "Yes" && <img className=" w-6 h-6" src={load} />}
                </button>
                <p
                  id="withdraw"
                  className=" ml-auto w-full text-right hidden text-[8px] md:text-xs mt-2 font-Nunito font-medium text-[#0CD16B]"
                >
                  *Your transaction has been received and will be processed
                  shortly
                </p>
              </div>
            </div>
          </div>
        </motion.div>
      )}
      {lowBalance && (
        <div className="w-[100%] h-[100vh] bg-[rgba(0,0,0,0.5)] z-[999] fixed text-white top-0 left-0 flex justify-center items-center md:px-5 overflow-y-auto">
          <div className=" px-10 py-8 bg-[#121212] rounded-[20px] justify-center flex flex-col relative items-center">
            <img
              src={close}
              onClick={() => {
                setLowBalance(false);
              }}
              className=" absolute right-4 top-3"
              alt=""
            />
            <img src={danger} alt="" />
            <p className=" mt-3 text-center font-Lato font-medium text-2xl ">
              Insufficient balance
            </p>
            <p className=" mt-3 text-center font-Lato font-normal text-base w-[240px]">
              You do not have enough funds in your wallet to complete this
              withdrawal. Please deposit more funds or reduce the amount you are
              withdrawing.
            </p>
          </div>
        </div>
      )}
      {upgrade && (
        <div className="w-[100%] h-[100vh] bg-[rgba(0,0,0,0.5)] z-[999] fixed text-white top-0 left-0 flex justify-center items-center md:px-5 overflow-y-auto">
          <div className=" px-10 py-8 bg-[#121212] rounded-[20px] justify-center flex flex-col relative items-center">
            <img
              src={close}
              onClick={() => {
                setUpgrade(false);
              }}
              className=" absolute right-4 top-3"
              alt=""
            />
            <img src={warner} alt="" />
            <p className=" mt-3 text-center font-Lato font-medium text-2xl ">
              Commision Fee
            </p>
            <p className=" mt-3 text-center font-Lato font-normal text-base w-[240px]">
              To withdraw funds, you need to pay a commision fee.
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export default ForexDash;
